
<template>
    <div>
        <activity-form type="1" ></activity-form>
    </div>
</template>

<script type="text/ecmascript-6">
import activityForm from '../activityForm/index'

export default {
    components: {
        activityForm
    },
}
</script>
